import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Button from '../components/Button';
import TopicsManager from '../topics/topicsManager.js';

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);

    let allPages = TopicsManager.getAllArticles();
    allPages = this.shuffleArray(allPages);
    this.state = {allPages: allPages};
  }

  shuffleArray(array) {
    for (let i = 0; i < array.length; i++) {
      let idx = i + Math.floor(Math.random() * (array.length - i));
      [array[i], array[idx]] = [array[idx], array[i]];
    }
    return array
  }

  shuffle = () => {
    let allPages = this.shuffleArray(this.state.allPages);
    this.setState({allPages: allPages});
  }

  render() {
    const allPages = this.state.allPages;
    return (
      <Layout>
        <SEO title="404 Not found" />
        <div className="prose mx-auto">
          <h1>404. Not Found</h1>

          <p>
            You just hit a page that doesn&#39;t exist...
          </p>

          <p>
            How about reading one of the these articles instead?
          </p>

          <div className="flex mt-8 flex-wrap md:flex-row">
            {
              allPages.slice(0, 3).map(page => (
                <span className="mr-4 mb-5">
                  <Button link={'/' + page.name}>{page.title}</Button>
                </span>
              ))
            }
            <Button className="mb-5" type='secondary' onClick={this.shuffle}>
              <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd"></path></svg>
            </Button>
          </div>

          <p>
            Or you could return to the <Link to='/'>home page</Link>.
          </p>

          <div className="mt-12 md:mt-16">
            <img className="mx-auto w-1/2 md:w-1/2" src="/hide.svg" />
          </div>

        </div>
      </Layout>
    )
  }
}

export default NotFoundPage
